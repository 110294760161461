import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import InstagramIcon from '@static/icons/instagram.svg';
import TwitterIcon from '@static/icons/twitter.svg';

const SOCIAL = [
  {
    icon: InstagramIcon,
    link: 'https://instagram.com/rachroobear/',
  },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/rachroobear',
  },
];

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        art_pot: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "customers_pot" }
        ) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        {/* <Art>
          <Img
            fluid={data.art_pot.childImageSharp.fluid}
            style={{ width: 480, maxWidth: '100%', marginBottom: -16 }}
          />
        </Art> */}
        <FooterWrapper>
          <StyledContainer className="funBorder">
            <Copyright>
              <h2>
                Writer of novels, essays, short stories, reported articles,
                to-do lists, love letters, and obituaries.
              </h2>
              <span className="hidden">
                Made for bü by Higgis McGee.
                {` `}
              </span>
            </Copyright>
            <SocialIcons>
              {SOCIAL.map(({ icon, link }) => (
                <ExternalLink href={link}>
                  <img src={icon} alt="link" />
                </ExternalLink>
              ))}
            </SocialIcons>
          </StyledContainer>
        </FooterWrapper>
      </React.Fragment>
    )}
  />
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  padding: 32px 0;
  /* TODO: Add back in once add background art near footer */
  /* margin-top: -10%; */

  .funBorder {
    border-top: 1px dashed #8a5bc6;
    padding-top: 24px;
  }
`;

const Copyright = styled.div`
  .hidden {
    color: ${props => props.theme.color.white.regular};
  }
  font-family: ${props => props.theme.font.secondary};
  color: ${props => props.theme.color.black.regular};
  max-width: 590px;
  margin-left: 137px;
  margin-right: 95px;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    h2 {
      ${props => props.theme.font_size.small};
      text-align: right;
    }
    margin-left: 163px;
    margin-right: 114px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 53px;
    margin-right: 31px;
  }
`;

const Art = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 48px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
